import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
// import QRCode from 'react-qr-code';
// import QRCode from 'qrcode.react';

import { QRCode } from 'react-qrcode-logo';
import { Spinner } from 'reactstrap';
import axios from '../../../API/axios';
import swal from "sweetalert";
import hyperLogo from './../../../images/logo5.jpeg';
import wowLogo from './../../../images/wow-logo-white-anim.svg';
const ViewEmployee = ({ guid, setViewContentModal, viewContentModal, idData }) => {
    // const includePoints = planbyIdData.planIncludePoints
    // const excludePoints = planbyIdData.planExludePoints
    // console.log('guid', idData);

    // const getEmpData = async () => {
    //     try {
    //         const res = await axios.get(`/Employee/?guid=${guid}`)
    //         // console.log('employeeResponse', res)
    //         // setEmployeeData(res?.data)
    //     } catch (error) {
    //         console.log('error', error)
    //     }
    // }

    // useEffect(() => {
    //     getEmpData()
    // }, [])
    // download QR code
    const downloadQRCode = () => {
        const qrCodeURL = document.getElementById('qrCodeEl')
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        // console.log(qrCodeURL)
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = "QR_Code.png";
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }
    const downloadQRCode1 = () => {
        const qrCodeURL = document.getElementById('qrCodeEl1')
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        // console.log(qrCodeURL)
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = "QR_Code.png";
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }
    return (
        <div>
            <Modal
                className="fade bd-example-modal-sm"
                show={viewContentModal}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>View Employee Details</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setViewContentModal(!viewContentModal)}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body >
                    {/* {isLoading ? <div className='d-flex justify-content-center my-1 primary' >
                        <Spinner />
                    </div> : */}
                    <>
                        <div className='row'>
                            <div className='col-md-6 mb-1'>
                                <div className="form-group mb-3">
                                    <label>Employee Id</label>
                                    <h6>{idData?.employeeID}</h6>
                                </div>
                            </div>
                            <div className='col-md-6 mb-1'>
                                <div className="form-group mb-3">
                                    <label>Name</label>
                                    <h6>{idData?.fullName}</h6>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group mb-3">
                                    <label>Phone Number</label>
                                    <h6>{idData?.telePhone}</h6>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group mb-3">
                                    <label>Telephone</label>
                                    <h6>{idData?.phneNumber}</h6>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group mb-3">
                                    <label>Email</label>
                                    <h6>{idData?.email}</h6>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group mb-3">
                                    <label>Website</label>
                                    <h6>{idData?.website}</h6>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group mb-3">
                                    <label>Designation</label>
                                    <h6>{idData?.designation}</h6>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="form-group mb-3">
                                    <label>Company</label>
                                    <h6>{idData?.company}</h6>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="form-group mb-3">
                                    <label>Address</label>
                                    <h6>{idData?.address}</h6>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="form-group mb-3">
                                    <label>Address 2</label>
                                    <h6>{idData?.address2}</h6>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="form-group mb-3">
                                    <label>Location</label>
                                    <h6>{idData?.location}</h6>
                                </div>
                            </div>


                            <div className='col-md-12'>
                                <div className="form-group mb-3">
                                    <label>Image</label>
                                    <div>
                                        <img src={idData?.imageURL} width='150px' />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 mt-2'>
                                <label>QR Code WowInfoBiz</label>
                                <div className='mb-5 qrcode'>
                                    {/* <QRCode id="qrCodeEl" value={idData?.qrLinkwow} className='ms-1' size={250}
                                    //style={{ width: '260px', height: '260px', border: '1px solid white' }} 

                                    /> */}
                                    <QRCode value={idData?.qrLinkwow} logoImage={wowLogo} id="qrCodeEl" size={250} />
                                    {/* <input
                                        type="button"
                                        className="download-btn"
                                        value="Download"
                                        onClick={downloadQRCode}
                                    /> */}
                                    {/* <img src={wowLogo} alt="" width='150px' className='logoImg1' /> */}
                                    <h4>Scan Me</h4>
                                    <Button variant="primary" type="submit" onClick={downloadQRCode} className='d-block' style={{ padding: '0.5rem 0.5rem' }} >
                                        Download
                                    </Button>
                                </div>
                                <p className='qrlink mb-0'>{idData?.qrLinkwow}</p>
                                <p className='pt-0' onClick={() => {
                                    navigator.clipboard.writeText(idData?.qrLinkwow)
                                    swal('link copied')
                                }} style={{ cursor: 'pointer', color: 'blue' }}>Copy</p>
                            </div>
                            <div className='col-md-6 mt-2'>
                                <label>QR Code HyperAutoX</label>
                                <div className='mb-5 qrcode'>
                                    {/* <QRCode id="qrCodeEl1" value={idData?.qrLinkHAX} className='ms-1 ' size={250}
                                    //style={{ width: '260px', height: '260px', border: '1px solid white' }} 

                                    /> */}
                                    <QRCode value={idData?.qrLinkHAX} logoImage={hyperLogo} id="qrCodeEl1" size={250} />
                                    <h4>Scan Me</h4>
                                    <Button variant="primary" type="submit" onClick={downloadQRCode1} className='d-block' style={{ padding: '0.5rem 0.5rem' }} >
                                        Download
                                    </Button>
                                </div>
                                <p className='qrlink mb-0'>{idData?.qrLinkHAX}</p>
                                <p className='pt-0' onClick={() => {
                                    navigator.clipboard.writeText(idData?.qrLinkHAX)
                                    swal('link copied')
                                }} style={{ cursor: 'pointer', color: 'blue' }}>Copy</p>
                            </div>
                        </div>


                        <Modal.Footer>
                            <Button
                                variant="danger light"
                                onClick={() => setViewContentModal(!viewContentModal)}
                            >
                                Close
                            </Button>
                        </Modal.Footer>
                    </>
                    {/* } */}
                </Modal.Body>
            </Modal>
        </div >
    )
}

export default ViewEmployee