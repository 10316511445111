import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
// import axios from '../../../API/axios';
import changePass from '../../images/reset-password.jpeg'
import axios from '../../API/axios';



const ChangePassword = () => {
    const [viewContentModal, setViewContentModal] = useState(false);
    const [oldpass, setOldpass] = useState('');
    const [newPass, setNewPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('');

    const sendingData = {
        emailId: "info@welinkjobs.com",
        oldPassword: oldpass,
        newPassword: newPass
    }

    const reset = () => {
        setOldpass("")
        setNewPass("")
        setConfirmPass("")
    }
    const passwordData = async (e) => {
        e.preventDefault();
        console.log(sendingData);
        if (newPass !== confirmPass) {
            swal("Error!", "New Password And Confirm Password Didn't Matched!", "error")
        } else if (oldpass === '' || newPass === '' || confirmPass === '') {
            swal("Error!", "Please Fill All The Fields!", "error")
        } else {
            try {
                await axios.post("/Account/Admin/ChangePassword", sendingData).then(() => {
                    swal("Password Changed Successfully!", {
                        icon: "success",
                    }).then(() => {
                        setViewContentModal(false)
                        reset()
                    })

                }).catch(() => {
                    swal("Error!", "Failed to save data!", "error");
                });
            } catch (error) {
                console.log(error);
            }
        }
    };
    return (
        <>
            <a href='#/' className='dropdown-item ai-icon' onClick={() => {
                setViewContentModal(true)
            }}>
                <img src={changePass} width={15} alt='change' />
                <span className="ms-2" >Change Password </span>
            </a>
            <Modal
                className="fade bd-example-modal-lg"
                show={viewContentModal}
                size="md"
            >
                <Modal.Header>
                    <Modal.Title>Change Password</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setViewContentModal(!viewContentModal)}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body >
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="form-group mb-3">
                                <label>Old Password</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle"
                                    onChange={(e) => setOldpass(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="form-group mb-3">
                                <label>New Password</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle"
                                    onChange={(e) => setNewPass(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className="form-group mb-3">
                                <label>Confirm Password</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle"
                                    onChange={(e) => setConfirmPass(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <Modal.Footer>
                        <Button
                            variant="danger light"
                            onClick={passwordData}
                        >
                            Save
                        </Button>
                        <Button
                            variant="danger light"
                            onClick={() => setViewContentModal(!viewContentModal)}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ChangePassword