import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import swal from "sweetalert";
import { Spinner } from "reactstrap"
// import axios from './../../../API/axios';
import Select from 'react-select';
import axios from "../../../API/axios";
import { HexColorPicker } from "react-colorful";
const EditEmployee = ({ setContentModal, contentModal, idData, guid, empid, getEmpData }) => {

    // console.log('editdetails', idData, 'empId', guid);
    // const [employeeData, setEmployeeData] = useState('')
    // console.log('employeeData', employeeData);
    const [empId, setEmpId] = useState('')
    const [empFName, setEmpFName] = useState('')
    const [empLName, setEmpLName] = useState('')
    const [empMName, setEmpMName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [telephone, setTelephone] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')
    const [address2, setAddress2] = useState('')
    const [website, setWebsite] = useState('')
    const [designation, setDesignation] = useState('')
    const [company, setCompany] = useState('')
    const [loading, setLoading] = useState(false)
    const [color, setColor] = useState(idData?.bgColorCode);
    const [location, setLocation] = useState('')
    const [countryList, setCountryList] = useState([]);
    const [code, setCode] = useState("");
    const [code1, setCode1] = useState("");
    const [editLogoName] = useState(idData?.image)
    const [editLogo, setEditLogo] = useState('')
    const getEditData = async () => {

        try {
            if (guid !== '') {
                const res = await axios.get(`/Employee/${empid}`)
                let data = res.data
                // console.log('employeeResponse', data)
                setEmpFName(data[0]?.fName)
                setEmpLName(data[0]?.lname)
                setEmpMName(data[0]?.mname)
                setPhoneNumber(data[0]?.telePhone)
                setTelephone(data[0]?.phneNumber)
                setEmail(data[0]?.email)
                setAddress(data[0]?.address)
                setAddress2(data[0]?.address2)
                setWebsite(data[0]?.website)
                setDesignation(data[0]?.designation)
                setCompany(data[0]?.company)
                setColor(data[0]?.bgColorCode)
                setLocation(data[0]?.location)
            }
            // setEmployeeData(res?.data)
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        getEditData()
    }, [guid])

    const handleEditEmployee = async (e) => {
        e.preventDefault()
        setLoading(true)
        let uploadedImage
        if (editLogo !== '') {
            let imageformData = new FormData()
            imageformData.append('file', editLogo)
            // imageformData.append('CompanyID', CompanyID)
            // console.log('imageformData', imageformData);
            try {
                const res = await axios({
                    method: "post",
                    // baseURL: `${Image_base_uri}`,
                    url: "/Employee/UploadImage",
                    data: imageformData,
                })
                // console.log('res', res);
                if (res.data.imageName) {
                    // setNewPoslogo(res.data.imageName)
                    // handleRefresh()
                    // setUploadImgStatus(true)
                    uploadedImage = res.data.imageName
                }
            } catch (error) {
                console.log('error', error)
                // setUploadImgStatus(false)
                return 0
            }
        }
        // console.log(uploadedImage);
        let obj = {
            "employeeID": idData?.employeeID,
            "fName": empFName,
            "lName": empLName,
            "mName": empMName,
            "fullName": empFName + " " + empMName + " " + empLName,
            // "telePhone": phoneNumber,
            // "phoneNumber": telephone,
            "telePhone": code + "" + phoneNumber,
            "phoneNumber": code1 + "" + telephone,
            "email": email,
            "address": address,
            "address2": address2,
            "designation": designation,
            "company": company,
            "website": website,
            "bgColorCode": color,
            "image": uploadedImage === undefined ? idData?.image : uploadedImage,
            "qrLinkwow": "",
            "qrLinkHAX": "",
            "location": location
        }
        console.log('obj', obj);
        try {
            const res = await axios.put(`/Employee/${guid}`, obj)
            // console.log('res', res)
            if (res.status === 200) {
                // console.log('toast');
                // toast.success('New Employee Details Added')
                swal("successfully Updated!", {
                    icon: "success",
                })
                setContentModal(false)
                getEmpData()
                setLoading(false)
            }
        } catch (error) {
            console.log('error', error)
            swal("Error!", "Failed to save data!", "error")
            setLoading(false)
        }
    }


    const getCountryList = async () => {
        try {
            await axios.get("/CountryCode").then((res) => {
                setCountryList(
                    res.data.map((country) => {
                        console.log(country);
                        return {
                            value: country?.code,
                            label: `${country?.code} ${country?.country}`,
                        };
                    })
                );
                // console.log(abc);
            });
        } catch (error) {
            console.log("🚀 ~ file: Register.js:138 ~ getCountryList ~ error", error);
        }
    };


    useEffect(() => {
        getCountryList();
    }, []);

    return (
        <div>
            <Modal
                className="fade bd-example-modal-lg"
                show={contentModal}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Edit Employee Details</Modal.Title>
                    <Button
                        variant=""
                        className="btn-close"
                        onClick={() => setContentModal(!contentModal)}
                    >
                    </Button>
                </Modal.Header>
                <Modal.Body >
                    <form onSubmit={e => handleEditEmployee(e)}>
                        <div className="row">
                            <div className="form-group mb-3 col-md-6">
                                <label>Employee Id: {idData?.employeeID}</label>
                                <input type="text" disabled='true' style={{ background: '#80808042' }} className="form-control input-default"
                                    name="planTitle" value={empId === '' ? idData?.employeeID : empId} onChange={e => setEmpId(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>First Name</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle" value={empFName} onChange={e => setEmpFName(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Middle Name</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle" value={empMName} onChange={e => setEmpMName(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Last Name</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle" value={empLName} onChange={e => setEmpLName(e.target.value)}
                                />
                            </div>

                        </div>

                        <div className="row">
                            <div className="form-group mb-3 col-md-6">
                                <label>TelePhone (Default) </label>
                                <div className="d-flex" >
                                    <div className="col-md-4" style={{ borderRadius: "13px", paddingTop: "3px" }}>
                                        <Select
                                            className="input-field"
                                            placeholder="country"
                                            options={countryList}
                                            value={countryList.filter((el) => el.value === code1)}
                                            onChange={(e) => {
                                                setCode1(e.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-8" style={{ marginLeft: "10px" }}>
                                        <input
                                            type="text"
                                            className="form-control input-default"
                                            name="planTitle"
                                            value={telephone}
                                            onChange={e => setTelephone(e.target.value)}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Phone Number (Secondary) </label>
                                <div className="d-flex" >
                                    <div className="col-md-4" style={{ borderRadius: "13px", paddingTop: "3px" }}>
                                        <Select
                                            className="input-field"
                                            placeholder="country"
                                            options={countryList}
                                            value={countryList.filter((el) => el.value === code)}
                                            onChange={(e) => {
                                                setCode(e.value);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-8" style={{ marginLeft: "10px" }}>
                                        <input
                                            type="text"
                                            className="form-control input-default"
                                            name="planTitle"
                                            value={phoneNumber}
                                            onChange={e => setPhoneNumber(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <div className="row">
                            <div className="form-group mb-3 col-md-6">
                                <label>Telephone (Default)</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle" value={telephone} onChange={e => setTelephone(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Phone Number (Secondary)</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}
                                />
                            </div>

                        </div> */}
                        <div className="row">


                            <div className="form-group mb-3 col-md-6">
                                <label>Email</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle" value={email} onChange={e => setEmail(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Website</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle" value={website} onChange={e => setWebsite(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="form-group mb-3 col-md-6">
                                <label>Designation</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle" value={designation} onChange={e => setDesignation(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>Company</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle" value={company} onChange={e => setCompany(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group mb-3">
                            <label>Address</label>
                            <input type="text" className="form-control input-default"
                                name="maxSendMail" value={address} onChange={e => setAddress(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label>Address 2</label>
                            <input type="text" className="form-control input-default"
                                name="maxSendMail" value={address2} onChange={e => setAddress2(e.target.value)} />
                        </div>
                        <div className="form-group mb-3">
                            <label className="d-block">Location Link</label>
                            <input
                                type="text"
                                className="form-control input-default"
                                name="planTitle"
                                value={location}
                                onChange={e => setLocation(e.target.value)}
                            />
                        </div>

                        <div className="row">

                            <div className="form-group mb-3 col-md-6">
                                <label>Image</label>
                                <input type="file" className="form-control input-default"
                                    name="planTitle" onChange={e => setEditLogo(e.target.files[0])}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-6">
                                <label>ImageName</label>
                                <input type="text" className="form-control input-default"
                                    name="planTitle"
                                    value={idData?.image}
                                    disabled
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group mb-3 ">
                                <label>Color Picker</label>
                                <HexColorPicker color={color} onChange={setColor} />
                                <input type="text" className="form-control input-default mt-3"
                                    name="planTitle"
                                    value={color}
                                    disabled
                                />
                            </div>
                        </div>
                        <Modal.Footer>
                            <Button
                                variant="danger light"
                                onClick={() => setContentModal(!contentModal)}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit" disabled={loading === true} >
                                {loading === false ? 'Update' : 'Loading...'}
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditEmployee