import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import swal from "sweetalert";
import { Spinner } from "reactstrap";
import axios from "./../../../API/axios";
// import addEmployee from "./addEmployee";
// import EditPlan from "./EditPlan";
// import ViewPlan from "./ViewPlan";
// import ReactPaginate from "react-paginate";
import "../../../css/style.css";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import ViewEmployee from "./ViewEmployee";
import DeleteEmployee from "./DeleteEmployee";
import editicon from './../../../images/edit.png';
import editicon1 from './../../../images/pencil.png';
import editicon2 from './../../../images/update.png';
import deleteicon from './../../../images/delete.png';

const PER_PAGE = 10;

const Employee = () => {

  const [contentModal, setContentModal] = useState(false);
  const [viewContentModal, setViewContentModal] = useState(false);
  const [addEmployee, setaddEmployee] = useState(false);
  const [employeeData, setEmployeeData] = useState('')
  const [guid, setGuid] = useState('')
  const [empid, setEmpId] = useState('')
  const [guidEdit, setGuidEdit] = useState('')
  const [guidDelete, setGuidDelete] = useState('')
  const [idData, setIdData] = useState('')

  const [delPr, setDelPr] = useState(false)
  const handleDelPr = () => setDelPr(!delPr)
  // console.log('employeeData', employeeData)

  const getEmpData = async () => {
    try {
      const res = await axios.get('/Employee')
      // console.log('employeeResponse', res)
      setEmployeeData(res?.data)
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    getEmpData()
  }, [])

  const [query, setQuery] = useState("")
  const search = (data) => {
    return data.filter(item =>
      item.fullName.toLowerCase().includes(query.toLowerCase()) ||
      item.phneNumber.toLowerCase().includes(query.toLowerCase()) ||
      item.email.toLowerCase().includes(query.toLowerCase())
    )
  }

  return (
    <>
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Employee</h4>
            <Button
              className="me-2"
              variant="info btn-rounded"
              onClick={() => setaddEmployee(!addEmployee)}
            >
              {/* <span className="btn-icon-start text-info">
                <i className="fa fa-plus color-info" />
              </span> */}
              Add Employee Details
            </Button>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive">
              <div
                id="patientTable_basic_table"
                className="dataTables_wrapper"
              >
                <input
                  type="text"
                  placeholder="search"
                  className="form-control input-default w-50"
                  onChange={e => setQuery(e.target.value)}
                />
                <table
                  id="example5"
                  className="display dataTable no-footer w-100"
                  style={{ minWidth: 845 }}
                  role="grid"
                  aria-describedby="example5_info"
                  bordered
                >
                  <thead>
                    <tr role="row">
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Type: activate to sort column ascending"
                        style={{ width: 50 }}
                      >
                        Employee ID
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="User: activate to sort column ascending"
                        style={{ width: 50 }}
                      >
                        Name
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Status: activate to sort column ascending"
                        style={{ width: 25 }}
                      >
                        Phone Number
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Joined: activate to sort column ascending"
                        style={{ width: 25 }}
                      >
                        Email
                      </th>
                      <th
                        className="sorting"
                        tabIndex={0}
                        aria-controls="example5"
                        rowSpan={1}
                        colSpan={1}
                        aria-label="Action: activate to sort column ascending"
                        style={{ width: 66 }}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {console.log('employeeData', employeeData)} */}
                    {!employeeData ? <h3 className="text-center w-100 p-2">No Data Found</h3> : employeeData && search(employeeData)?.map((item, index) => {
                      {/* { console.log('item', item) } */ }
                      return (

                        <tr role="row" className="odd" key={index} >
                          <td className="sorting_1">
                            <a
                              href="#/"
                              className="view-link"
                              onClick={() => {
                                setViewContentModal(true);
                                setGuid(item.guid)
                                setIdData(item)
                              }}
                            >
                              {item.employeeID}
                            </a>
                          </td>
                          <td className="sorting_1">
                            {item.fullName}
                          </td>
                          <td className="sorting_1">
                            {item.phneNumber}
                          </td>
                          <td className="sorting_1">
                            {item.email}
                          </td>
                          <td className="action-icons">
                            {/* <i
                              className="fa fa-edit me-2"
                              onClick={() => {
                                setContentModal(true);
                                setIdData(item)
                                setGuid(item.employeeID)
                              }}
                            ></i> */}
                            {/* <img src={editicon} onClick={() => {
                              setContentModal(true);
                              setIdData(item)
                              setGuid(item.employeeID)
                            }}
                              width='15px'
                              style={{ cursor: 'pointer' }}
                              className='me-2'
                            /> */}
                            <img src={editicon1} onClick={() => {
                              setContentModal(true);
                              setIdData(item)
                              setGuidEdit(item.guid)
                              setEmpId(item.employeeID)
                            }}
                              width='15px'
                              style={{ cursor: 'pointer' }}
                              className='me-2'
                            />
                            {/* <img src={editicon2} onClick={() => {
                              setContentModal(true);
                              setIdData(item)
                              setGuid(item.employeeID)
                            }}
                              width='15px'
                              style={{ cursor: 'pointer' }}
                              className='me-2'
                            /> */}
                            {/* <i
                              className="fa fa-trash"
                              onClick={() => {
                                // { console.log('txgh') }
                                setGuid(item.employeeID)
                                handleDelPr()

                              }}
                            ></i> */}
                            <img src={deleteicon}
                              onClick={() => {
                                // { console.log('txgh') }
                                setGuidDelete(item.guid)
                                handleDelPr()
                              }}
                              width='15px'
                              style={{ cursor: 'pointer' }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                    { }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditEmployee
        setContentModal={setContentModal}
        contentModal={contentModal}
        idData={idData}
        guid={guidEdit}
        empid={empid}
        getEmpData={getEmpData}
      />


      <AddEmployee
        setaddEmployee={setaddEmployee}
        addEmployee={addEmployee}
        getEmpData={getEmpData}
      />


      <ViewEmployee
        viewContentModal={viewContentModal}
        setViewContentModal={setViewContentModal}
        guid={guid}
        idData={idData}
      />
      <DeleteEmployee open={delPr} handleOpen={handleDelPr} id={guidDelete} getEmpData={getEmpData} />
    </>
  );
};

export default Employee;
