import axios from "axios"
// import jwtDefaultConfig from "../../src/@core/auth/jwt/jwtDefaultConfig";
// const BASE_URL = `http://192.168.0.171:5500/api`

const BASE_URL = `https://api.emp-dev.wowinfobiz.com/api`

// const jwtconfig = { ...jwtDefaultConfig }

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*', //
        'Content-Type': 'application/json',

    }
})

axiosInstance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // debugger
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // if (error.response.data.Message === "Invalid user or token") {
    //     // console.log(error)
    //     // location.replace("http://localhost:3000/login")
    //     window.location.href = "/login"
    //     localStorage.removeItem("userData")
    // } else return Promise.reject(error);
});

export default axiosInstance