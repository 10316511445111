import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useEffect, useState } from "react";
// import axios from "../../../API/axios";
import swal from "sweetalert";
import axios from "../../../API/axios";
import { toast } from "react-toastify";
// import ColorPicker from 'react-color-picker'
// import { ColorPicker, useColor } from "react-color-palette";
// import { SketchPicker } from 'react-color'
import { HexColorPicker } from "react-colorful";
const AddEmployee = ({ addEmployee, setaddEmployee, getEmpData }) => {

  const [empId, setEmpId] = useState('')
  const [empFName, setEmpFName] = useState('')
  const [empLName, setEmpLName] = useState('')
  const [empMName, setEmpMName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [telephone, setTelephone] = useState('')
  const [email, setEmail] = useState('')
  const [address, setAddress] = useState('')
  const [address2, setAddress2] = useState('')
  const [website, setWebsite] = useState('')
  const [designation, setDesignation] = useState('')
  const [company, setCompany] = useState('')
  const [logo, setLogo] = useState('')
  const [location, setLocation] = useState('')
  const [loading, setLoading] = useState(false)
  const [color, setColor] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [code, setCode] = useState("");
  const [code1, setCode1] = useState("");
  const reset = () => {
    setEmpId('')
    setEmpFName('')
    setEmpLName('')
    setEmpMName('')
    setPhoneNumber('')
    setEmail('')
    setAddress('')
    setDesignation('')
    setCompany('')
    setLogo('')
    setAddress2('')
    setWebsite('')
    setLocation('')
  }

  const handleAddEmployee = async (e) => {
    setLoading(true)
    e.preventDefault()
    let uploadedImage
    if (logo !== '') {
      let imageformData = new FormData()
      imageformData.append('File', logo)
      // console.log('imageformData', imageformData);
      try {
        const res = await axios({
          method: "post",
          // baseURL: `${axios}`,
          url: "/Employee/UploadImage",
          data: imageformData,
        })
        // console.log('res', res.data.imageName);
        if (res.data) {
          // setNewPoslogo(res.data.FileName)
          // handleRefresh()
          // setUploadImgStatus(true)
          uploadedImage = res.data.imageName
        }
      } catch (error) {
        console.log('error', error)
        // setUploadImgStatus(false)
        return 0
      }
    }
    // console.log(uploadedImage);

    let obj = {
      "employeeID": empId,
      "fName": empFName,
      "lName": empLName,
      "mName": empMName,
      "fullName": empFName + " " + empMName + " " + empLName,
      "telePhone": code + "" + phoneNumber,
      "phoneNumber": code1 + "" + telephone,
      "email": email,
      "address": address,
      "address2": address2,
      "website": website,
      "designation": designation,
      "company": company,
      "image": uploadedImage === undefined ? '' : uploadedImage,
      "qrLinkwow": "",
      "qrLinkHAX": "",
      "bgColorCode": color,
      "location": location
    }
    console.log('obj', obj);
    try {
      const res = await axios.post('/Employee', obj)
      // console.log('res', res.status)
      if (res.status === 200) {
        // console.log('toast');
        // toast.success('New Employee Details Added')
        swal("successfully saved!", {
          icon: "success",
        })
        setaddEmployee(false)
        getEmpData()
        reset()
        setLoading(false)
      } else {
        swal("Error!", "An employee ID already exists.!", "error")
      }
    } catch (error) {
      console.log('error', error)
      swal("Error!", "Failed to save data!", "error")
      setLoading(false)
    }
  }
  const getCountryList = async () => {
    try {
      await axios.get("/CountryCode").then((res) => {
        setCountryList(
          res.data.map((country) => {
            console.log(country);
            return {
              value: country?.code,
              label: `${country?.code} ${country?.country}`,
            };
          })
        );
        // console.log(abc);
      });
    } catch (error) {
      console.log("🚀 ~ file: Register.js:138 ~ getCountryList ~ error", error);
    }
  };


  useEffect(() => {
    getCountryList();
  }, []);
  return (
    <>
      <Modal className="fade bd-example-modal-lg" show={addEmployee} size="lg">
        <Modal.Header>
          <Modal.Title>Add Employee Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setaddEmployee(!addEmployee)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={e => handleAddEmployee(e)}>
            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label>Employee Id</label>
                <input
                  type="text"
                  className="form-control input-default"
                  name="planTitle"
                  onChange={e => setEmpId(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label>First Name</label>
                <input
                  type="text"
                  className="form-control input-default"
                  name="planTitle"
                  onChange={e => setEmpFName(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label>Middle Name</label>
                <input
                  type="text"
                  className="form-control input-default"
                  name="planTitle"
                  onChange={e => setEmpMName(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label>Last Name</label>
                <input
                  type="text"
                  className="form-control input-default"
                  name="planTitle"
                  onChange={e => setEmpLName(e.target.value)}
                />
              </div>

            </div>

            <div className="row">
              <div className="form-group mb-3 col-md-6">
                <label>TelePhone (Default) </label>
                <div className="d-flex" >
                  <div className="col-md-4" style={{ borderRadius: "13px", paddingTop: "3px" }}>
                    <Select
                      className="input-field"
                      placeholder="country"
                      options={countryList}
                      value={countryList.filter((el) => el.value === code1)}
                      onChange={(e) => {
                        setCode1(e.value);
                      }}
                    />
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "10px" }}>
                    <input
                      type="text"
                      className="form-control input-default"
                      name="planTitle"
                      onChange={e => setTelephone(e.target.value)}
                    />
                  </div>
                </div>

              </div>
              <div className="form-group mb-3 col-md-6">
                <label>Phone Number (Secondary) </label>
                <div className="d-flex" >
                  <div className="col-md-4" style={{ borderRadius: "13px", paddingTop: "3px" }}>
                    <Select
                      className="input-field"
                      placeholder="country"
                      options={countryList}
                      value={countryList.filter((el) => el.value === code)}
                      onChange={(e) => {
                        setCode(e.value);
                      }}
                    />
                  </div>
                  <div className="col-md-8" style={{ marginLeft: "10px" }}>
                    <input
                      type="text"
                      className="form-control input-default"
                      name="planTitle"
                      onChange={e => setPhoneNumber(e.target.value)}
                    />
                  </div>
                </div>
              </div>

            </div>
            <div className="row">


              <div className="form-group mb-3 col-md-6">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control input-default"
                  name="planTitle"
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label>Website</label>
                <input
                  type="text"
                  className="form-control input-default"
                  name="planTitle"
                  onChange={e => setWebsite(e.target.value)}
                />
              </div>
            </div>
            <div className="row">

              <div className="form-group mb-3 col-md-6">
                <label>Designation</label>
                <input
                  type="text"
                  className="form-control input-default"
                  name="planTitle"
                  onChange={e => setDesignation(e.target.value)}
                />
              </div>
              <div className="form-group mb-3 col-md-6">
                <label>Company</label>
                <input
                  type="text"
                  className="form-control input-default"
                  name="planTitle"
                  onChange={e => setCompany(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group mb-3">
              <label className="d-block">Address</label>
              <input
                type="text"
                className="form-control input-default"
                name="planTitle"
                onChange={e => setAddress(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label className="d-block">Address 2</label>
              <input
                type="text"
                className="form-control input-default"
                name="planTitle"
                onChange={e => setAddress2(e.target.value)}
              />
            </div>
            <div className="form-group mb-3">
              <label className="d-block">Location Link</label>
              <input
                type="text"
                className="form-control input-default"
                name="planTitle"
                onChange={e => setLocation(e.target.value)}
              />
            </div>

            <div className="row">

              <div className="form-group mb-3 ">
                <label>Image</label>
                <input
                  type="file"
                  className="form-control input-default"
                  name="planTitle"
                  onChange={e => setLogo(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group mb-3 ">
                <label>Color Picker</label>
                <HexColorPicker color={color} onChange={setColor} />
                <input type="text" className="form-control input-default mt-3"
                  name="planTitle"
                  value={color}
                  disabled
                />
              </div>
            </div>

            <Modal.Footer>
              <Button
                variant="danger light"
                onClick={() => {
                  setaddEmployee(!addEmployee);
                }}
              >
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={loading === true} >
                {loading === false ? 'Save' : 'Loading...'}
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddEmployee;
