
import React from 'react'
// import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import axios from "./../../../API/axios";
import swal from "sweetalert";
const DeleteEmployee = ({ open, handleOpen, id, getEmpData }) => {
    // console.log('id', id);
    const handleDelete = async () => {
        try {
            const res = await axios.delete(`Employee/${id}`)
            console.log('delres', res)
            if (res?.status === 200) {
                handleOpen()
                getEmpData()
                swal("successfully Deleted!", {
                    icon: "success",
                })
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    return (
        <>
            <Modal
                isOpen={open}
                toggle={handleOpen}
                className='modal-dialog-centered modal-md'
            >
                <ModalHeader toggle={handleOpen}>Delete Employee</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            Are you sure you want to delete this Employee Detail?
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Col className='text-center'>
                        <Button className='me-1' color='danger' onClick={handleDelete}>Delete</Button>
                        <Button className='ms-1' color='primary' onClick={handleOpen} outline>Cancel</Button>
                    </Col>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default DeleteEmployee